import { getEnv } from '@/lib/env'
import { useOnline } from '@vueuse/core'
import { reactive } from 'vue'
import { valueToBoolean, valueToString } from 'zeed'

const env: any = getEnv()

export const DEVELOPMENT = env.MODE !== 'production'
export const PRODUCTION = env.MODE === 'production'

// export const DEBUG = DEVELOPMENT
// export const TEST = false // +env.VITE_TEST

export const dist = reactive({
  expires: 0,
  status: '',
  silver: false,
  gold: false,
  notes: true,
  // docflow: false,
  // receipts: false,
  // payments: false,
})

export function getConfig(name: string, defaultValue?: string) {
  return env[name] ?? defaultValue
}

export const appConfig = {
  mode: 'app',
  env: env ?? {},
  // platform: {
  //   isMacOS: typeof navigator !== 'undefined' ? navigator.userAgent.includes(' Viidoo/') : false,
  // },
  app: {
    name: env.APP_NAME,
    title: env.APP_TITLE,
    version: env.APP_VERSION,
    release: env.APP_RELEASE,
    date: env.APP_DATE,
    timestamp: env.APP_TIMESTAMP,
    // author_name: env.APP_AUTHOR_NAME,
    // author_email: env.APP_AUTHOR_EMAIL,
  },
  sentry: {
    active: valueToBoolean(env.APP_SENTRY, PRODUCTION),
    dsn: valueToString(env.APP_SENTRY_DSN, 'https://61f4d7ae00d74f38a5e6c0d5bcd3816f@o120938.ingest.sentry.io/5579696'),
    // dsn: valueToString(env.APP_SENTRY_DSN, 'https://53c367d8b397417b819508c31ef6c57f@o120938.ingest.sentry.io/5826888'),
  },
  database: {
    /** Entries to be reached to compact into one single Yjs update */
    trimSize: 500,

    /** Allow data in indexedDB to be encrypted */
    allowCryptAtLocalRest: false,
    // TODO: How about assets?

    /** Yjs V2 is more compact but not as well integrated */
    // enableYjsV2: false,
  },
  list: {
    /** Count of list items used in the pool (50) */
    poolBuffer: 40,

    /** ms - Delay for updating detail view (500) */
    throttle: 250,
  },
  productionUrl: valueToString(env.APP_PRODUCTION_URL, 'https://viidoo.space'), // 'r2.dev.holtwick.de'),
  countryUrl: valueToString(env.APP_COUNTRY_URL, '/geoip'),
} as const

export interface AppConfig {
  getString: (name: string, defaultValue?: string) => string
  getBoolean: (name: string, defaultValue?: boolean) => boolean
  getInteger: (name: string, defaultValue?: number) => number
}

// TODO kind of senseless?

// export const appConfig2: AppConfig = {
//   getString(name, defaultValue = undefined) {
//     return getConfig(name, defaultValue)
//   },
//   getBoolean(name, defaultValue = undefined) {
//     return valueToBoolean(getConfig(name), defaultValue)
//   },
//   getInteger(name, defaultValue = undefined) {
//     return valueToInteger(getConfig(name), defaultValue)
//   },
// }

export const online = useOnline()

// const online = ref(navigator.onLine)
// window.addEventListener('offline', () => online.value = false)
// window.addEventListener('online', () => online.value = true)
