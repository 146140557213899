/* eslint-disable no-console */

import { setUuidDefaultEncoding } from 'zeed'
import { setupLogging } from './logger'

console.log('init')

setUuidDefaultEncoding('base32')

setupLogging().then(() => {
  import('./app-zugferd').then(async ({ setupApp }) => await setupApp()).catch(() => {})
}).catch(() => {})
