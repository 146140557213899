import { Logger, LoggerInterface } from 'zeed'

// Test common scenarios from Console
// Timeouts are required, because otherwise the error is directly handled by the console and not the Sentry catchers

// @ts-expect-error todo
window.bugTriggerSampleLogError = () => {
  setTimeout(() => {
    const log: LoggerInterface = Logger('demo')
    log.debug('Bug preceeding debug log')
    log.info('Bug preceeding info log')
    log.warn('Bug preceeding warn log')
    log.error('Bug preceeding error log')
    log.assert(false, 'TEST: Assert fails')
  })
}

// // @ts-expect-error todo
// window.bugTriggerSampleException = () => {
//   setTimeout(() => eval('}'), 500)
//   // throw new Error("Demo unhandled error")
// }

// @ts-expect-error todo
window.bugTriggerSampleReject = () => {
  new Promise((_, rej) => rej('Demo unhandled reject'))
}

// @ts-expect-error todo
window.bugTriggerUndefinedCall = function () {
  setTimeout(() => {
    // @ts-expect-error todo
    callUndefinedFunction('TEST: Undefined function call')
  }, 50)
}
