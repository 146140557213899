import { arrayFlatten, deepMerge, isArray, jsonStringifySafe, Logger } from 'zeed'

const log = Logger('network')

interface fetchOptionType {
  /** Returns the cache mode associated with request, which is a string indicating how the request will interact with the browser's cache when fetching. */
  cache?: RequestCache
  /** Returns the credentials mode associated with request, which is a string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL. */
  credentials?: RequestCredentials
  /** Returns the kind of resource requested by request, e.g., "document" or "script". */
  destination?: RequestDestination
  /** Returns a Headers object consisting of the headers associated with request. Note that headers added in the network layer by the user agent will not be accounted for in this object, e.g., the "Host" header. */
  headers?: Record<string, string>
  /** Returns request's subresource integrity metadata, which is a cryptographic hash of the resource being fetched. Its value consists of multiple hashes separated by whitespace. [SRI] */
  integrity?: string
  /** Returns a boolean indicating whether or not request can outlive the global in which it was created. */
  keepalive?: boolean
  /** Returns request's HTTP method, which is "GET" by default. */
  method?: string
  /** Returns the mode associated with request, which is a string indicating whether the request will use CORS, or will be restricted to same-origin URLs. */
  mode?: RequestMode
  /** Returns the redirect mode associated with request, which is a string indicating how redirects for the request will be handled during fetching. A request will follow redirects by default. */
  redirect?: RequestRedirect
  /** Returns the referrer of request. Its value can be a same-origin URL if explicitly set in init, the empty string to indicate no referrer, and "about:client" when defaulting to the global's default. This is used during fetching to determine the value of the `Referer` header of the request being made. */
  referrer?: string
  /** Returns the referrer policy associated with request. This is used during fetching to compute the value of the request's referrer. */
  referrerPolicy?: ReferrerPolicy
  /** Returns the signal associated with request, which is an AbortSignal object indicating whether or not request has been aborted, and its abort event handler. */
  signal?: AbortSignal
  /** Returns the URL of request as a string. */
  url?: string
  body?: any
}

type fetchOptionsType = fetchOptionType | fetchOptionsType[]

const defaultOptions: fetchOptionType = {
  cache: 'no-cache',
  redirect: 'follow',
  headers: {},
}

// Source https://developer.mozilla.org/de/docs/Web/HTTP/Methods
export type httpMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'HEAD'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

/** Simplified `fetch` that returns `undefined` on non 200 status */
async function fetchBasic(
  url: string | URL,
  fetchOptions: fetchOptionsType = {},
  fetchFn: (input: RequestInfo, init?: RequestInit) => Promise<Response> = fetch,
): Promise<Response | undefined> {
  try {
    if (isArray(fetchOptions))
      fetchOptions = deepMerge({}, ...arrayFlatten(fetchOptions))
    if (
      // @ts-expect-error headers
      fetchOptions.headers != null
      // @ts-expect-error headers
      && !(fetchOptions.headers instanceof Headers)
    ) {
      // @ts-expect-error headers
      fetchOptions.headers = new Headers(fetchOptions.headers)
    }

    // log("fetch", url, fetchOptions)
    const response = await fetchFn(String(url), fetchOptions as RequestInit)

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    if (response.status < 400)
      return response

    let responseDebug
    try {
      responseDebug = await response.text()
    }
    catch (err) { }
    log.warn(`fetchBasic ${url} status: ${response.status} text:`, responseDebug)
  }
  catch (err) {
    log.warn(`fetchBasic ${url}`, err)
  }
}

/** Fetch for JSON  */
async function fetchJson<T>(
  url: string | URL,
  fetchOptions: fetchOptionsType = {},
): Promise<T | undefined> {
  try {
    const res = await fetchBasic(
      url,
      [
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        },
        fetchOptions,
      ],
    )
    if (res)
      return await res.json()
  }
  catch (err) {
    log.warn('fetchJSON error:', err)
  }
}

/** Options to send data as JSON  */
function fetchOptionsJson(
  data: object,
  method: httpMethod = 'POST',
): fetchOptionType {
  return {
    method,
    ...defaultOptions,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // Accept: "application/json",
    },
    body: jsonStringifySafe(data),
  }
}

///

/** Fetch for text */
export async function netGetText(url: string | URL): Promise<string | undefined> {
  try {
    log.info(`netGetText: ${url}`)
    const res = await fetchBasic(
      url,
      [defaultOptions, { method: 'GET' }],
    )
    if (res)
      return await res.text()
  }
  catch (err) {
    log.warn(`netGetText ${url} error:`, err)
  }
}

/** GET JSON */
export async function netGetJson(url: string | URL): Promise<any> {
  log.info(`netGetJson: ${url}`)
  return await fetchJson(url)
}

/** POST JSON and recive JSON */
export async function netPostJson<T extends object>(url: string | URL, obj: T): Promise<object | undefined> {
  try {
    log.info(`netPostJson: ${url}`, obj)
    return await fetchJson(url, fetchOptionsJson(obj))
  }
  catch (err) {
    log.warn(`netPostJson ${url} error:`, err)
  }
}

/**
 * Post object data as JSON, expect 201
 */
export async function netPostBinaryJson<T extends object>(url: string, obj: T): Promise<boolean> {
  try {
    log.info(`netPostBinaryJson: ${url}`, obj)
    const result = await fetch(url, fetchOptionsJson(obj, 'POST'))
    return result.status === 201 || result.status === 200
  }
  catch (err) {
    log.warn(`netPostBinaryJson ${url} error:`, err)
  }
  return false
}

/**
 * Post binary data, expect 201
 */
export async function netPostBinary(url: string, bin: Uint8Array | File | Blob): Promise<boolean> {
  try {
    log.info(`netPostBinary: ${url}`, bin)
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: bin,
    })
    return result.status === 201 || result.status === 200
  }
  catch (err) {
    log.warn(`netPostBinary ${url} error:`, err)
  }
  return false
}

/**
 * Fetch as binary data. Accept 200 and 201
 */
export async function netGetBinary(url: string): Promise<Uint8Array | undefined> {
  try {
    log.info(`netGetBinary: ${url}`)
    const result = await fetch(url, {
      cache: 'default',
      method: 'GET',
    })
    if (result.status === 200 || result.status === 201) {
      const buffer = await result.arrayBuffer()
      return new Uint8Array(buffer)
    }
    else {
      log.warn(`netGetBinary error status=${result.status} url=${url}`)
    }
  }
  catch (err) {
    log.warn(`netGetBinary ${url} error:`, err)
  }
}
