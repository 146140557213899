import type { LogMessage } from 'zeed'
/* eslint-disable no-console */
import { appConfig } from '@/config'
import { netGetBinary, netPostJson } from '@/lib/network'
import { fetchJson, jsonStringifySafe, jsonToUint8Array } from 'zeed'

export function setupBug(messages: LogMessage[], fn?: () => void) {
  async function bugReportUpload() {
    const json = { messages }

    const result = await fetchJson(`${appConfig.productionUrl}/api/v1/support/upload`, {
      method: 'POST',
      // body,
      headers: { 'Content-Type': 'application/octet-stream' },
      body: jsonToUint8Array(json),
    }) as any
    if (result) {
      return {
        url: `${appConfig.productionUrl}/api/v1/support/log/${result.id}/logs.json`,
        urlDownload: `${appConfig.productionUrl}/api/v1/support/download/${result.id}/logs.json`,
      }
    }
  }

  async function bugReport() {
    const screenshotData = await netGetBinary('r2inbox:///screenshot')
    if (!screenshotData)
      return

    // eslint-disable-next-line no-alert
    const title = prompt('Give a title for the problem')
    if (title == null)
      return

    // eslint-disable-next-line no-alert
    const desc = prompt('Give a description to reproduce')
    if (desc == null)
      return

    console.log('Ready to send', { title, desc })

    const table = [
      ['URL', location.href],
      ['Release', appConfig.env.APP_RELEASE],
      ['Platform', MACOS ? 'macos' : 'web'],
      ['User Agent', String(navigator.userAgent)],
      ['Auth', localStorage.getItem('receiptsAuth') || 'n/a'],
    ].map(([name, value]) => `  <tr><th>${name}</th><td>${value}</td></tr>`).join('\n')

    console.log('Upload logs', messages)

    const upload = await bugReportUpload()

    console.log('Upload screenshot')

    const screenshotUpload = await fetchJson(`${appConfig.productionUrl}/api/v1/support/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/octet-stream' },
      body: screenshotData,
    }) as any

    const img = screenshotUpload ? `${appConfig.productionUrl}/api/v1/support/download/${screenshotUpload.id}/screenshot.png` : ''

    const body = `\
${desc}

---

<a href="${upload?.url}" target="_blank">Logs</a> (<a href="${upload?.urlDownload}" target="_blank">Download</a>)

<table>
${table}
</table>

<img src="${img}" alt="">
`

    //

    console.log('Upload bug')

    const result = await netPostJson(`${appConfig.productionUrl}/api/v1/support/issue`, {
      title,
      body,
    })

    // eslint-disable-next-line no-alert
    alert(`Bug has been sent: ${jsonStringifySafe(result)}`)
  }

  let b = false
  let u = false
  let g = false

  window.addEventListener('keypress', (event) => {
    if (event.key === 'b')
      b = true
    if (event.key === 'u')
      u = true
    if (event.key === 'g')
      g = true

    if (b && u && g) {
      // alert(1)
      (fn ?? bugReport)()

      b = false
      u = false
      g = false
    }
  })

  window.addEventListener('keyup', (event) => {
    if (event.key === 'b')
      b = false
    if (event.key === 'u')
      u = false
    if (event.key === 'g')
      g = false
  })
}
