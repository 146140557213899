/* eslint-disable node/prefer-global/process */

export function getEnv() {
  if (typeof process !== 'undefined')
    return process.env ?? {}

  try {
    return import.meta.env ?? {}
  }
  catch (err) {}

  return {}
}
