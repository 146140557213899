import type { LogHandler, LogMessage } from 'zeed'
import { LogLevelAll, LoggerBrowserHandler, LoggerMemoryHandler, getGlobalLogger } from 'zeed'
import { appConfig } from './config'
import { setupBug } from '@/debug'
import './lib/bug-triggers'

const maxLog = 500

let logCounter = 0

export const logMessages: LogMessage[] = []

export async function setupLogging() {
  const handlers: LogHandler[] = []

  const messages: LogMessage[] = []
  if (!localStorage.zeed) {
    handlers.push(
      LoggerMemoryHandler({
        level: LogLevelAll,
        filter: '*',
        messages,
      }),
    )
  }

  setupBug(messages)

  if (DEBUG && handlers.length === 0)
    return

  handlers.push(LoggerBrowserHandler())

  handlers.push((msg: LogMessage) => {
    if (++logCounter > maxLog)
      logMessages.splice(0, maxLog / 10)
    // console.log("msg", msg)
    logMessages.push(msg)
  })

  // todo sentry is not working with MAC
  // if (!DEBUG && appConfig.sentry.active) {
  //   const { LoggerSentryHandler, useSentry } = await import('./lib/sentry')
  //   if (useSentry()) {
  //     // eslint-disable-next-line no-console
  //     console.log('Setup sentry')
  //     handlers.push(LoggerSentryHandler())
  //   }
  //   else {
  //     console.warn('Problems setting up Sentry.io')
  //   }
  // }

  getGlobalLogger().setHandlers(handlers)
  getGlobalLogger().setLock(true)
}
